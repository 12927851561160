import _this from '../../main'

const colors = ["#FFAE57", "#FF7853", "#EA5151", "#CC3F57", "#9A2555"];

const itemStyle = {
    star5: {
        color: colors[0]
    },
    star4: {
        color: colors[1]
    },
    star3: {
        color: colors[2]
    },
    star2: {
        color: colors[3]
    }
};

const data = [{
        name: "教学\r\n设计能力",
        itemStyle: {
            color: "#A87B64",
        },
        label: {
            color: "#fff",
        },
        children: [{
                name: "学情分\r\n析能力\r\n138",
                itemStyle: {
                    color: "#CAA465"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: "10☆",
                    label: {
                        color: 'red',
                        fontSize: 18
                    },
                    children: [{
                        name: '参与活动10',
                        value: 1
                    }, {
                        name: '课程作业 70',
                        value: 1
                    }, {
                        name: '综合测评 53',
                        value: 1
                    }, {
                        name: '公开课 5',
                        value: 1
                    }]
                }]
            },
            {
                name: "教学过程\r\n设计能力\r\n92",
                itemStyle: {
                    color: "#C78869"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: "0☆",
                    label: {
                        color: 'red',
                        fontSize: 18
                    },
                    children: [{
                        name: '参与活动20',
                        value: 1
                    }, {
                        name: '课程作业 30',
                        value: 1
                    }, {
                        name: '综合测评 37',
                        value: 1
                    }, {
                        name: '公开课 5',
                        value: 1
                    }]
                }]
            },
            {
                name: "教学策略\r\n选择能力\r\n95",
                itemStyle: {
                    color: "#BB764C"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: "5☆",
                    label: {
                        color: 'red',
                        fontSize: 18
                    },
                    children: [{
                        name: '参与活动20',
                        value: 1
                    }, {
                        name: '课程作业 40',
                        value: 1
                    }, {
                        name: '综合测评 35',
                        value: 1
                    }]
                }]
            }
        ]
    },
    {
        name: "课程资源\r\n开发利用\r\n能力",
        itemStyle: {
            color: "#187A2F"
        },
        label: {
            color: "#fff",
        },
        children: [{
                name: "课程资源\r\n开发能力\r\n50",
                itemStyle: {
                    color: "#A2B029"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: "5☆",
                    label: {
                        color: 'red',
                        fontSize: 18

                    },
                    children: [{
                        name: '10 参与活动',
                        value: 1
                    }, {
                        name: '30 课程作业',
                        value: 1
                    }, {
                        name: '10 综合测评',
                        value: 1
                    }]
                }]
            },
            {
                name: "课程资源\r\n利用能力\r\n64",
                itemStyle: {
                    color: "#5E9A80"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: "0 ☆",
                    label: {
                        color: 'red',
                        fontSize: 18
                    },
                    children: [{
                        name: '20 参与活动',
                        value: 1
                    }, {
                        name: '35 课程作业',
                        value: 1
                    }, {
                        name: '9 综合测评',
                        value: 1
                    }]
                }]
            }
        ]
    },
    {
        name: "教学\r\n示范能力",
        itemStyle: {
            color: "#0AA3B5",
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "口语表\r\n达能力\r\n155",
            itemStyle: {
                color: "#76C0CB",
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: "10☆",
                label: {
                    color: 'red',
                    fontSize: 18
                },
                children: [{
                    name: '35 参与活动',
                    value: 1
                }, {
                    name: '110 课程作业 ',
                    value: 1
                }, {
                    name: '5 综合测评',
                    value: 1
                }, {
                    name: '5 公开课',
                    value: 1
                }]
            }]
        }]
    },
    {
        name: "教学\r\n管理\r\n能力",
        itemStyle: {
            color: "#E65832",
        },
        label: {
            color: "#fff",
            fontSize: '17',
        },
        children: [{
            name: "课堂调\r\n控能力\r\n180",
            itemStyle: {
                color: "#F89A80"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: "15☆",
                label: {
                    color: 'red',
                    fontSize: 18
                },
                children: [{
                    name: '105 参与活动',
                    value: 1
                }, {
                    name: '55 课程作业',
                    value: 1

                }, {
                    name: '20 综合测评',
                    value: 1
                }]
            }]
        }]
    },
    {
        name: "教学研究\r\n创新能力",
        itemStyle: {
            color: "#EBB40F",
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "学习研\r\n究能力\r\n42",
            itemStyle: {
                color: "#B09733"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: "0 ☆",
                label: {
                    color: 'red',
                    fontSize: 18
                },
                children: [{
                    name: '参与活动5',
                    value: 1
                }, {
                    name: '课程作业 22',
                    value: 1
                }, {
                    name: '综合测评 10',
                    value: 1
                }, {
                    name: '公开课 5',
                    value: 1
                }]
            }]
        }, {
            name: "创新\r\n能力\r\n22",
            itemStyle: {
                color: "#E1C315"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: "5 ☆",
                label: {
                    color: 'red',
                    fontSize: 18
                },
                children: [{
                    name: '参与活动 5',
                    value: 1
                }, {
                    name: '8 课程作业',
                    value: 1
                }, {
                    name: '9 综合测评',
                    value: 1
                }]
            }]
        }]
    },
    // {
    //     name: "教学\r\n管理",
    //     itemStyle: {
    //         color: "#5AC9E9"
    //     },
    //     children: [{
    //             name: "课堂组织",
    //             itemStyle: {
    //                 color: "#D6E0E9"
    //             },
    //             children: [{
    //                 name: "5☆",
    //                 value: 1
    //             }]
    //         },
    //         {
    //             name: "教学调控",
    //             itemStyle: {
    //                 color: "#5AC9E9"
    //             },
    //             children: [{
    //                 name: "5☆",
    //                 value: 1
    //             }]
    //         }
    //     ]
    // }
];
const dataes = [{
    name: 'Grandpa',
    children: [{
        name: 'Uncle Leo',
        value: 15,
        // children: [{
        //     name: 'Cousin Jack',
        //     value: 2
        // }, {
        //     name: 'Cousin Mary',
        //     value: 5,
        // }, {
        //     name: 'Cousin Ben',
        //     value: 4
        // }]
    }, {
        name: 'Father',
        value: 10,
        // children: [{
        //     name: 'Me',
        //     value: 5
        // }, {
        //     name: 'Brother Peter',
        //     value: 1
        // }]
    }, {
        name: 'Father',
        value: 10,
        // children: [{
        //     name: 'Me',
        //     value: 5
        // }, {
        //     name: 'Brother Peter',
        //     value: 1
        // }]
    }]
}];
// 能力报告-综合个人能力
// , value, index
export const myChartSunburstOption = function (data) {
    // console.log(data);

    let option = {
        // color: colors,
        // color: ['#4883F0','#C7D0D7','#5AC9E9','#D6E0E9','#67B6F9','#7669F3'],
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        // color: ['#da0d68','#da1d23','#ebb40f', '#187a2f','#0aa3b5','#c94930','#ad213e','#a87b64','#e65832'],
        series: [{
            type: "sunburst",
            center: ["50%", "50%"],
            radius: [0, '100%'],
            nodeClick: false,
            data: data,
            sort: function (a, b) {
                if (a.depth === 1) {
                    return b.getValue() - a.getValue();
                } else {
                    return a.dataIndex - b.dataIndex;
                }
            },
            emphasis: {
                focus: 'none'
            },
            label: {
                rotate: "radial",
                fontSize: 13,
                //     formatter: function (params) {
                //       var strlength = params.name.length;
                //       if (strlength % 4 == 2) {
                //         params.name = params.name.replace(/\S{4}/g, function (match) {
                //               // console.log(match);
                //               return match + '\n'
                //           })
                //       } else {
                //         params.name = params.name.replace(/\S{4}/g, function (match) {
                //               // console.log(match);
                //               return match + '\n'
                //           })
                //           strlength = params.name.length;
                //           params.name = params.name.substring(0, strlength);
                //       }
                //       return params.name
                //   },
            },
            itemStyle: {
                borderWidth: 2,
            },
            levels: [{},
                {
                    label: {
                        // show:false,
                        rotate: 'radial',
                        align: "center",
                        // minAngle: 25,
                    }
                },
                {
                    label: {
                        show: true,
                        rotate: 'radial',
                        align: "right",
                    }
                }
            ]
        }]
        // levels: [
        //     {
        //         // r0: 0,
        //         // r: 150,
        //         // label: {
        //         //     rotate: 0
        //         // },
        //         itemStyle: {
        //             color: 'orange'
        //         },
        //     },
        //     // {
        //     //     r0: 150,
        //     //     r: 262.5
        //     // },
        //     {
        //         r0: 262.5,
        //         r: 300,
        //         itemStyle: {
        //             shadowBlur: 2,
        //             shadowColor: colors[2],
        //             color: "transparent"
        //         },
        //         label: {
        //             rotate: "tangential",
        //             fontSize: 10,
        //             color: colors[0]
        //         }
        //     },
        //     {
        //         r0: 300,
        //         r: 310,
        //         itemStyle: {
        //             shadowBlur: 80,
        //             shadowColor: colors[0]
        //         },
        //         label: {
        //             position: "outside",
        //             textShadowColor: "#333"
        //         },
        //         downplay: {
        //             label: {
        //                 opacity: 0.5
        //             }
        //         }
        //     }
        // ]
        // }]
    }
    return option
}

const data1 = [{
        name: "思想\r\n品德",
        itemStyle: {
            color: "#EBB40F",
        },
        label: {
            color: "#fff",
        },
        children: [{
                name: "理想信念\r\n40",
                itemStyle: {
                    color: "#E1C315",
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: '参与活动 20',
                    value: 1,
                }, {
                    name: '课程作业 10',
                    value: 1,
                }, {
                    name: '公开课 10',
                    value: 1,
                }]
            },
            {
                name: "公民素质\r\n60",
                itemStyle: {
                    color: "#B09733"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: '参与活动 20',
                    value: 1,
                }, {
                    name: '20 课程作业',
                    value: 1,
                }, {
                    name: '10 综合测评',
                    value: 1,
                }, {
                    name: '10 公开课',
                    value: 1,
                }]
            },
        ]
    },
    {
        name: "学业\r\n水平",
        itemStyle: {
            color: "#187A2F"
        },
        label: {
            color: "#fff",
        },
        children: [{
                name: "学习行为\r\n40",
                itemStyle: {
                    color: "#A2B029"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: '10 参与活动',
                    value: 1,
                }, {
                    name: '20 课程作业',
                    value: 1,
                }, {
                    name: '10 综合测评',
                    value: 1,
                }]
            },
            {
                name: "学习效果\r\n40",
                itemStyle: {
                    color: "#5E9A80"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: '10 参与活动',
                    value: 1,
                }, {
                    name: '15 课程作业',
                    value: 1,
                }, {
                    name: '10 综合测评',
                    value: 1,
                }, {
                    name: '5 公开课',
                    value: 1,
                }]
            }
        ]
    },
    {
        name: "身心\r\n健康",
        itemStyle: {
            color: "#0AA3B5"
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "兴趣技能\r\n40",
            itemStyle: {
                color: "#9DB2B7"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '20 参与活动',
                value: 1,
            }, {
                name: '10 课程作业',
                value: 1,
            }, {
                name: '10 综合测评',
                value: 1,
            }]
        }, {
            name: "心理素养\r\n20",
            itemStyle: {
                color: "#76C0CB"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '10 参与活动',
                value: 1,
            }, {
                name: '10 公开课',
                value: 1,
            }]
        }],
    },
    {
        name: "艺术\r\n素养",
        itemStyle: {
            color: "#DA0D68"
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "特长表现\r\n40",
            itemStyle: {
                color: "#E0719C"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '参与活动 20',
                value: 1,
            }, {
                name: '课程作业 5',
                value: 1,
            }, {
                name: '综合测评 5',
                value: 1,
            }, {
                name: '公开课 10',
                value: 1,
            }]
        }, {
            name: "课外活动\r\n60",
            itemStyle: {
                color: "#975E6D"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '参与活动 30',
                value: 1,
            }, {
                name: '课程作业 5',
                value: 1,
            }, {
                name: '综合测评 10',
                value: 1,
            }, {
                name: '公开课 15',
                value: 1,
            }]
        }]
    },
    {
        name: "实践\r\n创新",
        itemStyle: {
            color: "#A87B64"
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "创新素养\r\n40",
            itemStyle: {
                color: "#C78869"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '参与活动 20',
                value: 1,
            }, {
                name: '课程作业 10',
                value: 1,
            }, {
                name: '综合测评 5',
                value: 1,
            }, {
                name: '公开课 5',
                value: 1,
            }]
        }, {
            name: "社会实践\r\n40",
            itemStyle: {
                color: "#BB764C"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '参与活动 20',
                value: 1,
            }, {
                name: '课程作业 10',
                value: 1,
            }, {
                name: '综合测评 5',
                value: 1,
            }, {
                name: '公开课 5',
                value: 1,
            }]
        }]
    },
    {
        name: "校本\r\n特色",
        itemStyle: {
            color: "#E65832"
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "校本特色\r\n40",
            itemStyle: {
                color: "#F89A80"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '20 参与活动',
                value: 1,
            }, {
                name: '5 课程作业',
                value: 1,
            }, {
                name: '10 综合测评',
                value: 1,
            }, {
                name: '5 公开课',
                value: 1,
            }]
        }]
    }
];

// 能力报告-综合个人能力-学生
export const myChartStudentSunburstOption = {
    color: colors,
    series: [{
        type: "sunburst",
        center: ["50%", "48%"],
        data: data1,
        sort: function (a, b) {
            if (a.depth === 1) {
                return b.getValue() - a.getValue();
            } else {
                return a.dataIndex - b.dataIndex;
            }
        },
        label: {
            rotate: "radial",
            color: "#333333",
            fontSize: 18,
            padding: 5
        },
        itemStyle: {
            borderWidth: 2
        },
        levels: [{},
            {
                r0: 0,
                r: '40%',
                label: {
                    rotate: 0
                }
            },
            {
                r0: '40%',
                r: '70%'
            },
            {
                r0: '70%',
                r: '72%',
                itemStyle: {
                    shadowBlur: 2,
                    shadowColor: colors[2],
                    color: "transparent"
                },
                label: {
                    position: 'outside',
                    textShadowColor: "#fff"
                }
            },
        ]
    }]
}

const data2 = [{
        name: "信息意识",
        itemStyle: {
            color: "#EBB40F",
        },
        label: {
            color: "#fff",
        },
        children: [{
                name: "信息获取\r\n4",
                label: {
                    color: "#fff",
                },
                itemStyle: {
                    color: "#E1C315"
                },
                children: [{
                    name: '全本阅读 2',
                    value: 1,
                }, {
                    name: '主题阅读 2',
                    value: 1,
                }]
            },
            {
                name: "信息积累\r\n3",
                itemStyle: {
                    color: "#B09733"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: '全本阅读 2',
                    value: 1,
                }, {
                    name: '主题阅读 1',
                    value: 1,
                }]
            },
        ]
    },
    {
        name: "组织概况",
        itemStyle: {
            color: "#187A2F"
        },
        label: {
            color: "#fff",
        },
        children: [{
                name: "内容探究\r\n3",
                itemStyle: {
                    color: "#A2B029"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: '全本阅读 2',
                    value: 1,
                }, {
                    name: '主题阅读 1',
                    value: 1,
                }]
            },
            {
                name: "抽象概括\r\n3",
                itemStyle: {
                    color: "#5E9A80"
                },
                label: {
                    color: "#fff",
                },
                children: [{
                    name: '全本阅读 1',
                    value: 1,
                }, {
                    name: '主题阅读 2',
                    value: 1,
                }]
            }
        ]
    },
    {
        name: "认知理解",
        itemStyle: {
            color: "#0AA3B5"
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "逻辑完整\r\n5",
            itemStyle: {
                color: "#9DB2B7"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '全本阅读 3',
                value: 1,
            }, {
                name: '主题阅读 2',
                value: 1,
            }]
        }, {
            name: "理解表达\r\n3",
            itemStyle: {
                color: "#76C0CB"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '1 全本阅读',
                value: 1,
            }, {
                name: '2 主题阅读',
                value: 1,
            }]
        }],
    },
    {
        name: "批判思维",
        itemStyle: {
            color: "#E65832"
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "假设分析\r\n3",
            itemStyle: {
                color: "#F89A80"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '2 全本阅读',
                value: 1,
            }, {
                name: '1 主题阅读',
                value: 1,
            }]
        }, {
            name: "思考创新\r\n2",
            itemStyle: {
                color: "#F37674"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '1 全本阅读',
                value: 1,
            }, {
                name: '1 主题阅读',
                value: 1,
            }]
        }]
    },
    {
        name: "实践应用",
        itemStyle: {
            color: "#A87B64"
        },
        label: {
            color: "#fff",
        },
        children: [{
            name: "思考运用\r\n2",
            itemStyle: {
                color: "#C78869"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '1 全本阅读',
                value: 1,
            }, {
                name: '1 主题阅读',
                value: 1,
            }]
        }, {
            name: "交流合作\r\n2",
            itemStyle: {
                color: "#BB764C"
            },
            label: {
                color: "#fff",
            },
            children: [{
                name: '1 全本阅读',
                value: 1,
            }, {
                name: '1 主题阅读',
                value: 1,
            }]
        }]
    }
];

// 能力报告-综合个人能力-学生-阅读能力
export const myChartStudentReadSunburstOption = {
    color: colors,
    series: [{
        type: "sunburst",
        center: ["50%", "48%"],
        data: data2,
        sort: function (a, b) {
            if (a.depth === 1) {
                return b.getValue() - a.getValue();
            } else {
                return a.dataIndex - b.dataIndex;
            }
        },
        label: {
            rotate: "radial",
            color: "#333333",
            fontSize: 18,
            padding: 5
        },
        itemStyle: {
            borderWidth: 2
        },
        levels: [{},
            {
                r0: 0,
                r: '40%',
                label: {
                    rotate: 0
                }
            },
            {
                r0: '40%',
                r: '70%'
            },
            {
                r0: '70%',
                r: '72%',
                itemStyle: {
                    shadowBlur: 2,
                    shadowColor: colors[2],
                    color: "transparent"
                },
                label: {
                    position: 'outside',
                    textShadowColor: "#333"
                }
            },
        ]
    }]
}
// 能力报告-个人能力图谱
// export const myChartRadarOption = {
//     tooltip: {
//         trigger: 'item',
//     },
//     radar: [{
//         indicator: [
//             { name: "学情分析能力", max: 200 },
//             { name: "教学过程设计能力", max: 200 },
//             { name: "教学策略\r\n选择能力", max: 200 },
//             { name: "课程资源开发能力", max: 200 },
//             { name: "课程资源利用能力", max: 200 },
//             { name: "口语表达能力", max: 200 },
//             { name: "课堂调控能力", max: 200 },
//             { name: "学习研究能力", max: 200 },
//             { name: "创新能力", max: 200 }
//         ],
//         radius: 310,
//         startAngle: 90,
//         splitNumber: 4,
//         shape: "circle",
//         name: {
//             formatter: "{value}",
//             textStyle: {
//                 color: "#000",
//                 fontWeight: "bold",
//                 fontSize: "18"
//             }
//         },
//         splitArea: {
//             areaStyle: {
//                 color: [
//                     "rgba(114, 172, 209, 0.2)",
//                     "rgba(114, 172, 209, 0.4)",
//                     "rgba(114, 172, 209, 0.6)",
//                     "rgba(114, 172, 209, 0.8)",
//                     "rgba(114, 172, 209, 1)"
//                 ],
//                 shadowColor: "rgba(0, 0, 0, 0.3)",
//                 shadowBlur: 10
//             }
//         },
//         axisLine: {
//             lineStyle: {
//                 color: "rgba(255, 255, 255, 0.5)"
//             }
//         },
//         splitLine: {
//             lineStyle: {
//                 color: "rgba(255, 255, 255, 0.5)"
//             }
//         }
//     }],
//     series: [{
//         name: "个人能力图谱",
//         type: "radar",
//         emphasis: {},
//         data: [{
//             // , 50, 64, 155, 180, 42, 22
//             value: [138, 92, 95, 50, 64, 155, 180, 42, 22],
//             symbol: "none", //设置拐点格式样式
//             lineStyle: {
//                 color: "transparent"
//             },
//             areaStyle: {
//                 color: "#126CC0"
//             }
//         }]
//     }]
// }
// 能力报告-个人能力图谱
export const myChartRadarOption = function (data, value, index) {
    let ChartRadarOption = {
        tooltip: {
            trigger: 'item',
        },
        radar: [{
            indicator: data,
            // [
            //     { name: "学情分析能力", max: 200 },
            //     { name: "教学过程设计能力", max: 200 },
            //     { name: "教学策略\r\n选择能力", max: 200 },
            //     { name: "课程资源开发能力", max: 200 },
            //     { name: "课程资源利用能力", max: 200 },
            //     { name: "口语表达能力", max: 200 },
            //     { name: "课堂调控能力", max: 200 },
            //     { name: "学习研究能力", max: 200 },
            //     { name: "创新能力", max: 200 }
            // ],
            radius: '60%',
            center: ['50%', '50%'],
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            name: {
                // formatter: "{value}",
                // formatter: function (text) {
                //     var strlength = text.length;
                //     if (strlength % 4 == 2) {
                //         text = text.replace(/\S{4}/g, function (match) {
                //             // console.log(match);
                //             return match + '\n'
                //         })
                //     } else {
                //         text = text.replace(/\S{4}/g, function (match) {
                //             // console.log(match);
                //             return match + '\n'
                //         })
                //         strlength = text.length;
                //         text = text.substring(0, strlength);
                //     }
                //     return text
                // },
                color: "#333",
                fontSize: "15"
            },
            splitArea: {
                areaStyle: {
                    color: [
                        "rgba(255, 255,255,1)",
                        "rgba(255, 255,255,1)",
                        "rgba(255, 255,255,1)",
                        "rgba(255, 255,255,1)",
                        "rgba(255, 255,255,1)",

                    ],
                    shadowColor: "rgba(0, 0, 0, 0)",
                    // shadowBlur: 10
                }
            },
            axisLine: {
                lineStyle: {
                    color: "#E1F1FE"
                }
            },
            splitLine: {
                lineStyle: {
                    color: "#E5F3FE"
                }
            }
        }],
        series: [{
            name: "个人能力图谱",
            type: "radar",
            emphasis: {},
            data: [{
                value: value,
                symbol: "none", //设置拐点格式样式
                lineStyle: {
                    color: "transparent"
                },
                areaStyle: {
                    // color: "#126CC0"
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0,
                            color: '#15A1E2' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#126CC0' // 100% 处的颜色
                        }],
                    }

                }
            }]
        }]
    }
    return ChartRadarOption;
}

// 能力报告-个人能力图谱-学生
export const myChartStudentRadarOption = {
    tooltip: {
        trigger: 'item',
    },
    radar: [{
        indicator: [{
                name: "思想品德",
                max: 120
            },
            {
                name: "学业水平",
                max: 120
            },
            {
                name: "身心健康",
                max: 120
            },
            {
                name: "艺术素养",
                max: 120
            },
            {
                name: "实践创新",
                max: 120
            },
            {
                name: "校本特色",
                max: 120
            }
        ],
        radius: 310,
        startAngle: 90,
        splitNumber: 4,
        shape: "circle",
        name: {
            formatter: "{value}",
            textStyle: {
                color: "#000",
                fontWeight: "bold",
                fontSize: "18"
            }
        },
        splitArea: {
            areaStyle: {
                color: [
                    "rgba(114, 172, 209, 0.2)",
                    "rgba(114, 172, 209, 0.4)",
                    "rgba(114, 172, 209, 0.6)",
                    "rgba(114, 172, 209, 0.8)",
                    "rgba(114, 172, 209, 1)"
                ],
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 10
            }
        },
        axisLine: {
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        },
        splitLine: {
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        }
    }],
    series: [{
        name: "个人能力图谱",
        type: "radar",
        emphasis: {},
        data: [{
            value: [100, 80, 60, 100, 80, 40],
            symbol: "none", //设置拐点格式样式
            lineStyle: {
                color: "transparent"
            },
            areaStyle: {
                color: "#126CC0"
            }
        }]
    }]
}

// 能力报告-个人能力图谱-学生-阅读能力
export const myChartStudentReadRadarOption = {
    tooltip: {
        trigger: 'item',
    },
    radar: [{
        indicator: [{
                name: "信息意识",
                max: 10
            },
            {
                name: "组织概括",
                max: 10
            },
            {
                name: "认知理解",
                max: 10
            },
            {
                name: "批判思维",
                max: 10
            },
            {
                name: "实践应用",
                max: 10
            }
        ],
        radius: 310,
        startAngle: 90,
        splitNumber: 4,
        shape: "circle",
        name: {
            formatter: "{value}",
            textStyle: {
                color: "#000",
                fontWeight: "bold",
                fontSize: "18"
            }
        },
        splitArea: {
            areaStyle: {
                color: [
                    "rgba(114, 172, 209, 0.2)",
                    "rgba(114, 172, 209, 0.4)",
                    "rgba(114, 172, 209, 0.6)",
                    "rgba(114, 172, 209, 0.8)",
                    "rgba(114, 172, 209, 1)"
                ],
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 10
            }
        },
        axisLine: {
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        },
        splitLine: {
            lineStyle: {
                color: "rgba(255, 255, 255, 0.5)"
            }
        }
    }],
    series: [{
        name: "个人能力图谱",
        type: "radar",
        emphasis: {},
        data: [{
            value: [7, 6, 8, 5, 4],
            symbol: "none", //设置拐点格式样式
            lineStyle: {
                color: "transparent"
            },
            areaStyle: {
                color: "#126CC0"
            }
        }]
    }]
}

// 能力报告-各维度能力水平
export const myChartPiecesOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    legend: {
        data: ["个人能力", "最高分", "平均分"],
        top: 10,
        right: 100
    },
    color: ['#2373EC', '#0BDF00'],
    xAxis: [{
        type: "category",
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        },
        data: [
            "教学设计能力",
            "课程资源开发、\r\n利用能力",
            "教学示范能力",
            "教学管理能力",
            "教学研究创新能力"
        ],
        axisPointer: {
            type: "shadow"
        }
    }],
    yAxis: [{
            type: "value",
            name: "分值",
            min: 0,
            max: 500,
            interval: 100,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "",
            min: 0,
            max: 500,
            interval: 100,
            axisLabel: {
                show: false
            }
        }
    ],
    series: [{
            name: "个人能力",
            type: "bar",
            data: [325, 114, 155, 180, 64]
        },
        {
            name: "最高分",
            type: "bar",
            data: [400, 300, 200, 200, 200]
        },
        {
            name: "平均分",
            type: "line",
            yAxisIndex: 1,
            data: [286, 160, 120, 155, 120]
        }
    ]
}

// 能力报告-各维度能力水平
export const myChartAbilityAvgOption = function (data, myScore, mostScore, avgScore, maxValue) {
    let AbilityAvg = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999"
                }
            }
        },
        legend: {
            data: ["最高分", "个人能力", "平均分"],
            top: 10,
            right: 100,
            itemHeight: 12,
        },
        xAxis: [{
            type: "category",
            // 强制全部显示
            axisLabel: {
                color: "#333",
                interval: 0,
                formatter: function (text) {
                    var strlength = text.length;
                    if (strlength % 6 == 1) {
                        text = text.replace(/\S{6}/g, function (match) {
                            // console.log(match);
                            return match + '\n'
                        })
                    } else {
                        text = text.replace(/\S{6}/g, function (match) {
                            // console.log(match);
                            return match + '\n'
                        })
                        strlength = text.length;
                        text = text.substring(0, strlength);
                    }
                    return text
                },
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(183, 183, 183, .88)",
                },
            },
            // 刻度线
            axisTick: {
                alignWithLabel: true,
                inside: true,
                length: 270,
                lineStyle: {
                    type: 'dotted',
                    color: 'rgba(191, 191, 192, 0.7)'
                }
            },
            data: data,
            axisPointer: {
                type: "shadow"
            }
        }],
        yAxis: [{
                type: "value",
                name: "最高分",
                // min: 0,
                max: maxValue,
                nameTextStyle: {
                    color: "#888888"
                },
                interval: 500,
                axisLabel: {
                    formatter: "{value}",
                    color: "#888888",
                    fontSize: "11"
                },
                axisLine: {
                    lineStyle: {
                        color: "rgba(183, 183, 183, .8)",
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false
                }
            },
            { // 
                type: "value",
                name: "个人能力",
                nameTextStyle: {
                    color: "#888888"
                },
                // min: 0,
                max: maxValue,
                interval: 100,
                axisLabel: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: "rgba(183, 183, 183, .88)",
                    },
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    alignWithLabel: false,
                    show: false,
                },
            },
            { // 
                type: "value",
                name: "平均分",
                // min: 0,
                max: maxValue,
                show: false,
                interval: 100,
                offset: 55,
            }
        ],
        series: [{
                name: "个人能力",
                type: "bar",
                data: myScore,
                barMaxWidth: 15,
                yAxisIndex: 0,
                itemStyle: {
                    //柱状颜色
                    normal: {
                        barBorderRadius: [5, 5, 0, 0],
                        color: new _this.$echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [{
                                    offset: 1,
                                    color: '#00C9F8'
                                },
                                {
                                    offset: 0,
                                    color: '#009AED'
                                }

                            ]
                        )
                    }
                },

            },
            {
                name: "最高分",
                type: "bar",
                data: mostScore,
                barMaxWidth: 15,
                barGap: '80%',
                yAxisIndex: 1,
                itemStyle: {
                    //柱状颜色
                    normal: {
                        barBorderRadius: [5, 5, 0, 0],
                        color: new _this.$echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [{
                                    offset: 1,
                                    color: '#E96240'
                                },
                                {
                                    offset: 0,
                                    color: '#FF8E72'
                                }

                            ]
                        )
                    }
                },
            },
            {
                name: "平均分",
                type: "line",

                data: avgScore,
                symbolSize: 3,
                itemStyle: {
                    //线颜色
                    color: "#70A7FF",
                },
            }
        ]
    }
    return AbilityAvg;
}

// 能力报告-各维度能力水平-学生-阅读能力
export const myChartStudentReadPiecesOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    legend: {
        data: ["个人能力", "最高分", "平均分"],
        top: 10,
        right: 100
    },
    color: ['#2373EC', '#0BDF00'],
    xAxis: [{
        type: "category",
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        },
        data: [
            "信息意识",
            "组织概括",
            "认知理解",
            "批判思维",
            "实践应用"
        ],
        axisPointer: {
            type: "shadow"
        }
    }],
    yAxis: [{
            type: "value",
            name: "分值",
            min: 0,
            max: 10,
            interval: 2,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "",
            min: 0,
            max: 10,
            interval: 2,
            axisLabel: {
                show: false
            }
        }
    ],
    series: [{
            name: "个人能力",
            type: "bar",
            data: [7, 6, 8, 5, 4]
        },
        {
            name: "最高分",
            type: "bar",
            data: [10, 10, 10, 10, 10, 10]
        },
        {
            name: "平均分",
            type: "line",
            yAxisIndex: 1,
            data: [6, 6, 6, 5, 5]
        }
    ]
}

// 能力报告-各维度具体参与情况11111
export const myChartSimpleOption = function (data, value, dimensionsArray) {
    let Option = {
        legend: {
            top: 10,
            right: 100
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                crossStyle: {
                    color: "#999"
                }
            }
        },
        color: ['#2373EC', '#F96843', '#0DDD0D', '#9073F9'],
        dataset: {
            dimensions: dimensionsArray,
            source: data,
            // source: [{product: "准确性维度", 课程: 4}],
            // [
            //     ["product", "参与活动", "课程作业", "综合测评", "公开课"],
            //     ["教学设计能力", 50, 150, 115, 10],
            //     ["课程资源开发利用能力", 30, 65, 19, 10],
            //     ["教学示范能力", 35, 110, 5, 10],
            //     ["教学管理能力", 105, 55, 20, 10],
            //     ["教学研究创新能力", 10, 30, 19, 5],
            // ]
        },
        xAxis: {
            type: "category",
            // 刻度线
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                color: "#333",
                interval: 0,
                formatter: function (text) {
                    var strlength = text.length;
                    if (strlength % 6 == 1) {
                        text = text.replace(/\S{6}/g, function (match) {
                            // console.log(match);
                            return match + '\n'
                        })
                    } else {
                        text = text.replace(/\S{6}/g, function (match) {
                            // console.log(match);
                            return match + '\n'
                        })
                        strlength = text.length;
                        text = text.substring(0, strlength);
                    }
                    return text
                },
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(183, 183, 183, .88)",
                },
            },
            axisTick: {
                alignWithLabel: true,
                inside: true,
                length: 270,
                lineStyle: {
                    type: 'dotted',
                    color: 'rgba(191, 191, 192, 0.7)'
                }
            },
        },
        yAxis: {
            name: "得分",
            nameTextStyle: {
                color: "#888888"
            },
            axisLabel: {
                color: "#888888",
                fontSize: "11"
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(183, 183, 183, .8)",
                },
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: false
            }
        },
        series: value
        // series: [
        // {type: "bar", barMaxWidth: 30},
        // {type: "bar", barMaxWidth: 30,name:'作业'},
        // {type: "bar", barMaxWidth: 30,name:'活动'},
        // ]
    }
    return Option
}

// 能力报告-各维度具体参与情况-学生
export const myChartStudentSimpleOption = {
    legend: {
        top: 10,
        right: 100
    },
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    color: ['#2373EC', '#F96843', '#0DDD0D', '#9073F9'],
    dataset: {
        source: [
            ["product", "参与活动", "课程作业", "综合测评", "公开课"],
            ["思想品德", 40, 30, 10, 20],
            ["学业水平", 20, 35, 20, 5],
            ["身心健康", 30, 10, 10, 10],
            ["艺术素养", 50, 10, 15, 25],
            ["实践创新", 40, 20, 10, 10],
            ["校本特色", 20, 5, 10, 5],
        ]
    },
    xAxis: {
        type: "category",
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{
            type: "bar"
        },
        {
            type: "bar"
        },
        {
            type: "bar"
        },
        {
            type: "bar"
        }
    ]
}

// 能力报告-各维度具体参与情况-学生-阅读能力
export const myChartStudentReadSimpleOption = {
    legend: {
        top: 10,
        right: 100
    },
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    color: ['#2373EC', '#F96843'],
    dataset: {
        source: [
            ["product", "全本阅读", "主题阅读"],
            ["信息意识", 4, 3],
            ["组织概括", 3, 3],
            ["认知理解", 4, 4],
            ["批判思维", 3, 2],
            ["实践应用", 2, 2]
        ]
    },
    xAxis: {
        type: "category",
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{
            type: "bar"
        },
        {
            type: "bar"
        }
    ]
}

export const myChartCoursesNumberOption = {
    title: {
        text: '课程数量 0',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
        data: ['教师课', '学生课', '课程类型3'],
        bottom: 0,
        width: "80%",
    },
    color: ['#a6a6a6'],
    series: [{
        name: '访问来源',
        type: 'pie',
        radius: ['20%', '52%'],
        avoidLabelOverlap: false,
        label: {
            show: false,
            position: 'center'
        },
        labelLine: {
            show: false
        },
        data: [{
            value: 0,
            name: ''
        }, ]
    }]
}
export const myChartCourseAverageOption = {
    title: {
        text: '作业平均提交率 0%',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
        data: ['出勤率＜ 50 %', '50%≤出勤率≤80%', '出勤率＞80%'],
        bottom: 0,
        width: "100%",
    },
    color: ['#a6a6a6'],
    series: [{
        name: '访问来源',
        type: 'pie',
        radius: ['20%', '52%'],
        avoidLabelOverlap: false,
        label: {
            show: false,
            position: 'center'
        },
        labelLine: {
            show: false
        },
        data: [{
                value: 0,
                name: ''
            },
            // {
            //     value: 0,
            //     name: '出勤率＜ 50 %'
            // },
            // {
            //     value: 55,
            //     name: '50%≤出勤率≤80%'
            // },
            // {
            //     value: 45,
            //     name: '出勤率＞80%'
            // },
        ]
    }]
}
export const myChartProjectResourcesOption = {
    title: {
        text: '学员成果数量 0',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
        data: ['图片', '文本', '音频', '视频'],
        bottom: 0,
        width: "60%",
    },
    color: ['#a6a6a6', '#D458F2', '#4882F8', '#F8D448'],
    series: [{
        name: '访问来源',
        type: 'pie',
        radius: ['20%', '52%'],
        avoidLabelOverlap: false,
        label: {
            show: false,
            position: 'center'
        },
        labelLine: {
            show: false
        },
        data: [{
                value: 0,
                name: ''
            },
            // {
            //     value: 30,
            //     name: '文本'
            // },
            // {
            //     value: 20,
            //     name: '音频'
            // },
            // {
            //     value: 2,
            //     name: '视频'
            // },
        ]
    }]
}

export const myChartExpertTeamOption = {
    title: {
        text: '专家团队 0',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
        data: ['大学教授', '教研员及特级教师', '高级及其他教师'],
        bottom: 0,
        type: 'scroll',
    },
    color: ['#a6a6a6', '#4882F8', '#F8D448'],
    series: [{
        name: '访问来源',
        type: 'pie',
        radius: ['20%', '52%'],
        avoidLabelOverlap: false,
        label: {
            show: false,
            position: 'center'
        },
        labelLine: {
            show: false
        },
        data: [{
                value: 0,
                name: '大学教授'
            },
            // {
            //     value: 35,
            //     name: '教研员及特级教师'
            // },
            // {
            //     value: 50,
            //     name: '高级及其他教师'
            // },
        ]
    }]
}

// 领导-首页-能力图谱-教师
export const myChartCustomOption = {
    legend: {
        data: ['能力评价分', '能力最高分']
    },
    tooltip: {
        trigger: 'item',
    },
    radar: [{
        // splitArea: {
        //     areaStyle: {
        //         color: ['rgba(114, 172, 209, 0.2)',
        //             'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
        //             'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)'
        //         ],
        //         shadowColor: 'rgba(0, 0, 0, 0.3)',
        //         shadowBlur: 10
        //     }
        // },
        // axisLine: {
        //     lineStyle: {
        //         color: 'rgba(255, 255, 255, 0.5)'
        //     }
        // },
        // splitLine: {
        //     lineStyle: {
        //         color: 'rgba(255, 255, 255, 0.5)'
        //     }
        // }
    }, {
        indicator: [{
                name: '学情分析能力',
                max: 200
            },
            {
                name: '教学过程设计能力',
                max: 200
            },
            {
                name: '教学策略选择能力',
                max: 200
            },
            {
                name: '课程资源开发能力',
                max: 200
            },
            {
                name: '课程资源利用能力',
                max: 200
            },
            {
                name: '口语表达能力',
                max: 200
            },
            {
                name: '课堂调控能力',
                max: 200
            },
            {
                name: '学习研究能力',
                max: 200
            },
            {
                name: '创新能力',
                max: 200
            }
        ],
        center: ['50%', '50%'],
        radius: 120
    }],
    series: [{
        name: '能力图谱',
        type: 'radar',
        radarIndex: 1,
        data: [{
            value: [138, 83, 65, 70, 90, 120, 155, 65, 55],
            label: {
                // show: true,
                // formatter: function (params) {
                //     return params.value;
                // }
            },
            // areaStyle: {
            //     opacity: 0.9,
            //     color: "#72ACD1"
            // }
        }, ]
    }]
}

// 领导-首页-能力图谱-学生
export const myChartStudentOption = {
    legend: {
        data: ['一级能力分', '最高能力分']
    },
    tooltip: {
        trigger: 'item',
    },
    radar: [{
        splitArea: {
            areaStyle: {
                color: ['rgba(114, 172, 209, 0.2)',
                    'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
                    'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)'
                ],
                shadowColor: 'rgba(0, 0, 0, 0.3)',
                shadowBlur: 10
            }
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.5)'
            }
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.5)'
            }
        }
    }, {
        indicator: [{
                text: '思想品德',
                max: 120
            },
            {
                text: '学业水平',
                max: 120
            },
            {
                text: '身心健康',
                max: 120
            },
            {
                text: '艺术素养',
                max: 120
            },
            {
                text: '实践创新',
                max: 120
            },
            {
                text: '校本特色',
                max: 120
            },
        ],
        center: ['50%', '50%'],
        radius: 120
    }],
    series: [{
        name: '能力图谱',
        type: 'radar',
        radarIndex: 1,
        data: [{
            value: [100, 100, 89, 74, 89, 40],
            label: {
                // show: true,
                formatter: function (params) {
                    return params.value;
                }
            },
            areaStyle: {
                opacity: 0.9,
                color: "#72ACD1"
            }
        }, ]
    }]
}

// 领导-首页-课程情况
export const myChartCourseOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        },
        formatter: function (params) {
            return params[0].axisValue + "<br/>" + params[0].seriesName + "：" + params[0].value + "<br/>" + params[1].seriesName + "：" + params[1].value + "%";
        }
    },
    legend: {
        data: ["开课次数", "完课率"],
        bottom: 0
    },
    xAxis: [{
        type: "category",
        data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月"
        ],
        axisPointer: {
            type: "shadow"
        },
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    }],
    yAxis: [{
            type: "value",
            name: "开课次数",
            min: 0,
            max: 20,
            interval: 4,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "完课率",
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
                formatter: "{value} %"
            }
        }
    ],
    series: [{
            name: "开课次数",
            type: "bar",
            data: [0, 0, 2, 15, 16, 16, 12, 0, 1, 1],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },

        },
        {
            name: "完课率",
            type: "line",
            yAxisIndex: 1,
            data: [0.00, 0.00, 100.00, 98.23, 81.94, 72.90, 74.86, 0.00, 88.16, 79.17],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },
        }
    ]
}

// 领导-首页-活动情况
export const myChartActiveOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    legend: {
        data: ["举行次数", "参与率"],
        bottom: 0
    },
    xAxis: [{
        type: "category",
        data: [
            "10月",
            "11月",
            "12月",
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月"
        ],
        axisPointer: {
            type: "shadow"
        },
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    }],
    yAxis: [{
            type: "value",
            name: "举行次数",
            min: 0,
            max: 250,
            interval: 50,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "参与率",
            min: 0,
            max: 25,
            interval: 5,
            axisLabel: {
                formatter: "{value} %"
            }
        }
    ],
    series: [{
            name: "举行次数",
            type: "bar",
            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 2.0, 2.2, 3.3, 4.5, 6.3],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },

        },
        {
            name: "参与率",
            type: "line",
            yAxisIndex: 1,
            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 2.0, 2.2, 3.3, 4.5, 6.3],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },
        }
    ]
}

// 领导-首页-作业情况
export const myChartTaskOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        },
        formatter: function (params) {
            return params[0].axisValue + "<br/>" + params[0].seriesName + "：" + params[0].value + "<br/>" + params[1].seriesName + "：" + params[1].value + "%" + "<br/>" + params[2].seriesName + "：" + params[2].value + "%";
        }
    },
    legend: {
        data: ["作业次数", "完成率", "合格率"],
        bottom: 0
    },
    xAxis: [{
        type: "category",
        data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月"
        ],
        axisPointer: {
            type: "shadow"
        },
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    }],
    yAxis: [{
            type: "value",
            name: "作业次数",
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "完成率/合格率",
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
                formatter: "{value} %"
            }
        }
    ],
    series: [{
            name: "作业次数",
            type: "bar",
            data: [0, 0, 8, 65, 59, 59, 39, 0, 0, 0],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },

        },
        {
            name: "完成率",
            type: "line",
            yAxisIndex: 1,
            data: [0.00, 0.00, 75.67, 93.34, 83.22, 76.94, 64.57, 0.00, 0.00, 0.00],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },
        },
        {
            name: "合格率",
            type: "line",
            yAxisIndex: 1,
            data: [0.00, 0.00, 67.00, 75.00, 78.00, 82.00, 80.00, 0.00, 0.00, 0.00],
            itemStyle: {
                //柱状颜色
                color: "#FF6C60",
            },
        }
    ]
}

// 领导-首页-京银互动-课程情况
export const myChartInteractionCourseOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        },
        formatter: function (params) {
            return params[0].axisValue + "<br/>" + params[0].seriesName + "：" + params[0].value + "<br/>" + params[1].seriesName + "：" + params[1].value + "%";
        }
    },
    legend: {
        data: ["开课次数", "完课率"],
        bottom: 0
    },
    xAxis: [{
        type: "category",
        data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月"
        ],
        axisPointer: {
            type: "shadow"
        },
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    }],
    yAxis: [{
            type: "value",
            name: "开课次数",
            min: 0,
            max: 5,
            interval: 1,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "完课率",
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
                formatter: "{value} %"
            }
        }
    ],
    series: [{
            name: "开课次数",
            type: "bar",
            data: [0, 0, 0, 0, 0, 3, 1, 3, 1, 0],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },

        },
        {
            name: "完课率",
            type: "line",
            yAxisIndex: 1,
            data: [0.00, 0.00, 0.00, 0.00, 0.00, 90.00, 83.00, 86.90, 83.30, 0.00],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },
        }
    ]
}

// 领导-首页-京银互动-活动情况
export const myChartInteractionActiveOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    legend: {
        data: ["举行次数", "参与人数"],
        bottom: 0
    },
    xAxis: [{
        type: "category",
        data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
        ],
        axisPointer: {
            type: "shadow"
        },
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    }],
    yAxis: [{
            type: "value",
            name: "举行次数",
            min: 0,
            max: 5,
            interval: 1,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "参与人数",
            min: 0,
            max: 30000,
            interval: 6000,
            axisLabel: {
                formatter: "{value}"
            }
        }
    ],
    series: [{
            name: "举行次数",
            type: "bar",
            data: [1, 1, 1, 1, 0, 1, 1, 1, 0, 0],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },

        },
        {
            name: "参与人数",
            type: "line",
            yAxisIndex: 1,
            data: [28715, 28715, 15274, 9717, 0, 7242, 10933, 8281, 0, 0],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },
        }
    ]
}

// 领导-首页-京银互动-作业情况
export const myChartInteractionTaskOption = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        },
        formatter: function (params) {
            return params[0].axisValue + "<br/>" + params[0].seriesName + "：" + params[0].value + "<br/>" + params[1].seriesName + "：" + params[1].value + "%" + "<br/>" + params[2].seriesName + "：" + params[2].value + "%";
        }
    },
    legend: {
        data: ["作业次数", "完成率", "合格率"],
        bottom: 0
    },
    xAxis: [{
        type: "category",
        data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月"
        ],
        axisPointer: {
            type: "shadow"
        },
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    }],
    yAxis: [{
            type: "value",
            name: "作业次数",
            min: 0,
            max: 10,
            interval: 2,
            axisLabel: {
                formatter: "{value}"
            }
        },
        {
            type: "value",
            name: "完成率/合格率",
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
                formatter: "{value} %"
            }
        }
    ],
    series: [{
            name: "作业次数",
            type: "bar",
            data: [0, 0, 0, 0, 0, 6, 1, 2, 1, 0],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },

        },
        {
            name: "完成率",
            type: "line",
            yAxisIndex: 1,
            data: [0.00, 0.00, 0.00, 0.00, 0.00, 79.89, 70.00, 34.83, 50.00, 0.00],
            itemStyle: {
                //柱状颜色
                color: "#00A0EF",
            },
        },
        {
            name: "合格率",
            type: "line",
            yAxisIndex: 1,
            data: [0.00, 0.00, 0.00, 0.00, 0.00, 72.00, 78.00, 83.00, 85.00, 0.00],
            itemStyle: {
                //柱状颜色
                color: "#FF6C60",
            },
        }
    ]
}

// 得分情况
export const myChartScoreOption = {
    tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
    },
    legend: {
        // orient: 'vertical',
        // top: 'middle',
        bottom: 10,
        left: 'center',
        data: ['90-100分', '80-89分', '70-79分', '60-69分', '60分及以下']
    },
    series: [{
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        // 隐藏提示线
        label: {
            normal: {
                position: 'inner',
                show: false
            }
        },
        data: [{
                value: 20,
                name: '90-100分'
            },
            {
                value: 10,
                name: '80-89分'
            },
            {
                value: 3,
                name: '70-79分'
            },
            {
                value: 1,
                name: '60-69分'
            },
            {
                value: 1,
                name: '60分及以下'
            }
        ],
        emphasis: {
            itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
        }
    }]
}

// 资源格式统计
export const myChartResourcesOption = {
    tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
    },
    series: [{
        type: 'pie',
        radius: '50%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        label: {
            formatter: '{b}:{d}%'
        },
        data: [{
                value: 30,
                name: 'doc'
            },
            {
                value: 25,
                name: 'ppt'
            },
            {
                value: 15,
                name: 'pdf'
            },
            {
                value: 10,
                name: 'jpg'
            }
        ],
        emphasis: {
            itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
        }
    }]
}

// 客观题正确率
export const myChartObjectiveOption = {
    color: ['#3398DB'],
    tooltip: {
        trigger: 'axis',
        axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: '{a}:{c}%'
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: [{
        type: 'category',
        data: ['第1题', '第2题', '第3题', '第4题', '第5题'],
        axisTick: {
            alignWithLabel: true
        }
    }],
    yAxis: [{
        type: 'value',
        max: 100,
        interval: 25,
        axisLabel: {
            formatter: "{value} %"
        }
    }],
    series: [{
        name: '正确率',
        type: 'bar',
        barWidth: '60%',
        data: [80, 100, 80, 90, 90]
    }]
}

export const myChartPerformanceOption = {
    legend: {},
    tooltip: {},
    dataset: {
        source: [
            ['product', '个人能力得分', '能力平均分', '能力总分'],
            ['教学设计能力', 7, 6.5, 10],
            ['课程资源开发、利用能力', 3, 5.5, 10],
            ['教学示范能力', 7, 5, 10],
            ['教学管理能力', 2, 3, 5],
            ['教学研究创新能力', 1.5, 2, 5]
        ]
    },
    xAxis: {
        type: 'category',
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    },
    yAxis: {
        type: "value",
        name: "分值",
        min: 0,
        max: 10,
        interval: 2.5,
        axisLabel: {
            formatter: "{value}"
        }
    },
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{
            type: 'bar'
        },
        {
            type: 'bar'
        },
        {
            type: 'bar'
        }
    ]
}

export const myChartStudentScoreOption = {
    color: '#F96843',
    xAxis: {
        type: 'category',
        data: ['高丽娜', '张海燕', '周玄', '杨玲', '马慧玲', '王一飞', '刘虹', '史姝迪', '杨玉玉', '张蕾'],
        // 强制全部显示
        axisLabel: {
            interval: 0
        },
        // 刻度线
        axisTick: {
            alignWithLabel: true
        }
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        data: [8, 7, 9, 8, 9, 10, 7, 8, 8, 10],
        type: 'bar',
        backgroundStyle: {
            color: '#F96843'
        }
    }]
}

// 各维度能力平均分-作业统计
export const myChartAverageOption = {
    legend: {
        data: ['能力评价分', '能力最高分']
    },
    tooltip: {
        trigger: 'item',
    },
    radar: [{
        splitArea: {
            areaStyle: {
                color: ['rgba(114, 172, 209, 0.2)',
                    'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
                    'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)'
                ],
                shadowColor: 'rgba(0, 0, 0, 0.3)',
                shadowBlur: 10
            }
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.5)'
            }
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.5)'
            }
        }
    }, {
        indicator: [{
                text: '学情分析能力',
                max: 10
            },
            {
                text: '教学过程设计能力',
                max: 10
            },
            {
                text: '教学策略选择能力',
                max: 10
            },
            {
                text: '课程资源开发能力',
                max: 10
            },
            {
                text: '课程资源利用能力',
                max: 10
            },
            {
                text: '口语表达能力',
                max: 10
            },
            {
                text: '课堂调控能力',
                max: 10
            },
            {
                text: '学习研究能力',
                max: 10
            },
            {
                text: '创新能力',
                max: 10
            }
        ],
        center: ['50%', '50%'],
        radius: 120
    }],
    series: [{
        name: '能力图谱',
        type: 'radar',
        radarIndex: 1,
        data: [{
            value: [10, 4, 3, 4, 5, 8, 10, 2, 2],
            label: {
                // show: true,
                formatter: function (params) {
                    return params.value;
                }
            },
            areaStyle: {
                opacity: 0.9,
                color: "#72ACD1"
            }
        }, ]
    }]
}

// 各维度能力平均分-学员数据
export const myChartStudentDataOption = {
    legend: {
        data: ['能力评价分', '能力最高分']
    },
    tooltip: {
        trigger: 'item',
    },
    radar: [{
        splitArea: {
            areaStyle: {
                color: ['rgba(114, 172, 209, 0.2)',
                    'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
                    'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)'
                ],
                shadowColor: 'rgba(0, 0, 0, 0.3)',
                shadowBlur: 10
            }
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.5)'
            }
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.5)'
            }
        }
    }, {
        indicator: [{
                text: '学情分析能力',
                max: 200
            },
            {
                text: '教学过程设计能力',
                max: 200
            },
            {
                text: '教学策略选择能力',
                max: 200
            },
            {
                text: '课程资源开发能力',
                max: 200
            },
            {
                text: '课程资源利用能力',
                max: 200
            },
            {
                text: '口语表达能力',
                max: 200
            },
            {
                text: '课堂调控能力',
                max: 200
            },
            {
                text: '学习研究能力',
                max: 200
            },
            {
                text: '创新能力',
                max: 200
            }
        ],
        center: ['50%', '50%'],
        radius: 120
    }],
    series: [{
        name: '能力图谱',
        type: 'radar',
        radarIndex: 1,
        data: [{
            value: [138, 83, 65, 70, 90, 120, 155, 65, 55],
            label: {
                // show: true,
                formatter: function (params) {
                    return params.value;
                }
            },
            areaStyle: {
                opacity: 0.9,
                color: "#72ACD1"
            }
        }, ]
    }]
}